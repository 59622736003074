var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loaded)?_c('div',[(_vm.showRequests)?_c('b-row',[_c('b-col',{staticClass:"mx-auto",attrs:{"md":"6"}},[_c('b-card',{attrs:{"title":_vm.$t('Add') + ' ' + _vm.config.title.single}},[_c('validation-observer',{ref:"emailValidation"},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"mx-auto",staticStyle:{"margin-bottom":"8px"}},[_c('b-form-input',{attrs:{"state":errors.length && _vm.partnerEmail > 0 ? false : null,"placeholder":"Email"},model:{value:(_vm.partnerEmail),callback:function ($$v) {_vm.partnerEmail=$$v},expression:"partnerEmail"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.partnerEmail,"variant":"outline-primary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('Add'))+" ")])],1)],1),(errors.length && _vm.partnerEmail)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,2802350582)})],1)],1)],1),_c('b-col',{staticClass:"mx-auto",attrs:{"md":"6"}},[_c('b-card',{attrs:{"title":_vm.$t('Select Partners')}},[_c('b-row',[_c('b-col',{staticClass:"mx-auto",attrs:{"md":"6"}},[_c('b-button',{attrs:{"to":'/' +
                  (_vm.isSupplier ? 'seller-selection' : 'supplier-selection'),"variant":"primary"}},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.$t('Browse')))])])],1),_c('b-col',{staticClass:"mx-auto text-right",attrs:{"md":"6"}},[_c('b-img',{attrs:{"src":_vm.appLogoImage,"width":"120","alt":"logo"}})],1)],1)],1)],1)],1):_c('b-card',{attrs:{"title":_vm.$t('Add') + ' ' + _vm.config.title.single}},[_c('b-button',{attrs:{"href":_vm.eshopUrl + '/mpu-subscriptions',"variant":"success","target":"_blank"}},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.$t('Upgrade')))])])],1)],1):_vm._e(),_c('b-card',[_c('b-tabs',{attrs:{"pills":""}},[_c('b-tab',{attrs:{"title":_vm.$t('Requests_Active'),"active":""}},[_c('EntityList',{ref:"activeList",attrs:{"config":_vm.activeConfig,"columns":_vm.columns,"formatted-columns":_vm.formattedColumns,"form-fields":_vm.formFields,"custom-query":{
            personal: true,
            status: 'ACTIVE',
          },"has-insert-btn":false,"allow-duplicate":false,"extra-actions":[
            {
              callback: 'updatePartnerStatus_DELETED',
              icon: 'Trash2Icon',
              title: 'Delete',
              variant: 'danger',
            } ],"authority":"Users_Partner_Connect","hide-edit":"","hide-delete":"","inline-actions":""}})],1),_c('b-tab',{attrs:{"title":_vm.$t('Requests_Pending'),"lazy":""}},[_c('EntityList',{attrs:{"config":_vm.config,"columns":_vm.columns,"formatted-columns":_vm.formattedColumns,"form-fields":_vm.formFields,"custom-query":{
            personal: true,
            status: 'PENDING',
          },"has-insert-btn":false,"allow-duplicate":false,"extra-actions":[
            {
              callback: 'updatePartnerStatus_ACTIVE',
              icon: 'CheckCircleIcon',
              title: 'Approve',
              variant: 'success',
              renderCondition: {
                key: 'receiverId',
                value: _vm.userId,
              },
            },
            {
              callback: 'updatePartnerStatus_DECLINED',
              icon: 'XIcon',
              title: 'Decline',
              variant: 'danger',
              renderCondition: {
                key: 'receiverId',
                value: _vm.userId,
              },
            },
            {
              callback: 'updatePartnerStatus_DELETED',
              icon: 'Trash2Icon',
              title: 'Delete',
              variant: 'danger',
              renderCondition: {
                key: 'senderId',
                value: _vm.userId,
              },
            } ],"authority":"Users_Partner_Connect","hide-edit":"","hide-delete":"","inline-actions":""}})],1),_c('b-tab',{attrs:{"title":_vm.$t('Requests_Declined'),"lazy":""}},[_c('EntityList',{attrs:{"config":_vm.config,"columns":_vm.columnsDeclined,"formatted-columns":_vm.formattedColumns,"custom-query":{
            personal: true,
            status: 'DECLINED',
          },"has-insert-btn":false,"allow-duplicate":false,"extra-actions":[
            {
              callback: 'updatePartnerStatus_ACTIVE',
              icon: 'CheckCircleIcon',
              title: 'Approve',
              variant: 'success',
              renderCondition: {
                key: 'receiverId',
                value: _vm.userId,
              },
            } ],"authority":"Users_Partner_Connect","hide-edit":"","hide-delete":"","inline-actions":""}})],1),_c('b-tab',{attrs:{"title":_vm.$t('Requests_Deleted'),"lazy":""}},[_c('EntityList',{attrs:{"config":_vm.config,"columns":_vm.columnsDeleted,"formatted-columns":_vm.formattedColumns,"form-fields":_vm.formFields,"custom-query":{
            personal: true,
            status: 'DELETED',
          },"has-insert-btn":false,"authority":"Users_Partner_Connect"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }