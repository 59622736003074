<template>
  <div>
    <div v-if="loaded">
      <b-row v-if="showRequests">
        <b-col md="6" class="mx-auto">
          <b-card :title="$t('Add') + ' ' + config.title.single">
            <validation-observer ref="emailValidation">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-input-group class="mx-auto" style="margin-bottom: 8px">
                  <b-form-input
                    v-model="partnerEmail"
                    :state="errors.length && partnerEmail > 0 ? false : null"
                    placeholder="Email"
                  />

                  <b-input-group-append>
                    <b-button
                      :disabled="!partnerEmail"
                      variant="outline-primary"
                      @click.prevent="validationForm"
                    >
                      {{ $t('Add') }}
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <!-- to avoid error message displaying even after submitting the email v-if condition included-->
                <small v-if="errors.length && partnerEmail" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </validation-observer>
          </b-card>
        </b-col>
        <b-col md="6" class="mx-auto">
          <b-card :title="$t('Select Partners')">
            <b-row>
              <b-col md="6" class="mx-auto">
                <b-button
                  :to="
                    '/' +
                    (isSupplier ? 'seller-selection' : 'supplier-selection')
                  "
                  variant="primary"
                >
                  <span class="text-nowrap">{{ $t('Browse') }}</span>
                </b-button>
              </b-col>
              <b-col md="6" class="mx-auto text-right">
                <b-img :src="appLogoImage" width="120" alt="logo" />
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-card v-else :title="$t('Add') + ' ' + config.title.single">
        <b-button 
          :href="eshopUrl + '/mpu-subscriptions'"
          variant="success" 
          target="_blank"
        >
          <span class="text-nowrap">{{ $t('Upgrade') }}</span>
        </b-button>
      </b-card>
    </div>

    <b-card>
      <b-tabs pills>
        <b-tab :title="$t('Requests_Active')" active>
          <EntityList
            ref="activeList"
            :config="activeConfig"
            :columns="columns"
            :formatted-columns="formattedColumns"
            :form-fields="formFields"
            :custom-query="{
              personal: true,
              status: 'ACTIVE',
            }"
            :has-insert-btn="false"
            :allow-duplicate="false"
            :extra-actions="[
              {
                callback: 'updatePartnerStatus_DELETED',
                icon: 'Trash2Icon',
                title: 'Delete',
                variant: 'danger',
              },
            ]"
            authority="Users_Partner_Connect"
            hide-edit
            hide-delete
            inline-actions
          />
        </b-tab>

        <b-tab :title="$t('Requests_Pending')" lazy>
          <EntityList
            :config="config"
            :columns="columns"
            :formatted-columns="formattedColumns"
            :form-fields="formFields"
            :custom-query="{
              personal: true,
              status: 'PENDING',
            }"
            :has-insert-btn="false"
            :allow-duplicate="false"
            :extra-actions="[
              {
                callback: 'updatePartnerStatus_ACTIVE',
                icon: 'CheckCircleIcon',
                title: 'Approve',
                variant: 'success',
                renderCondition: {
                  key: 'receiverId',
                  value: userId,
                },
              },
              {
                callback: 'updatePartnerStatus_DECLINED',
                icon: 'XIcon',
                title: 'Decline',
                variant: 'danger',
                renderCondition: {
                  key: 'receiverId',
                  value: userId,
                },
              },
              {
                callback: 'updatePartnerStatus_DELETED',
                icon: 'Trash2Icon',
                title: 'Delete',
                variant: 'danger',
                renderCondition: {
                  key: 'senderId',
                  value: userId,
                },
              },
            ]"
            authority="Users_Partner_Connect"
            hide-edit
            hide-delete
            inline-actions
          />
        </b-tab>

        <b-tab :title="$t('Requests_Declined')" lazy>
          <EntityList
            :config="config"
            :columns="columnsDeclined"
            :formatted-columns="formattedColumns"
            :custom-query="{
              personal: true,
              status: 'DECLINED',
            }"
            :has-insert-btn="false"
            :allow-duplicate="false"
            :extra-actions="[
              {
                callback: 'updatePartnerStatus_ACTIVE',
                icon: 'CheckCircleIcon',
                title: 'Approve',
                variant: 'success',
                renderCondition: {
                  key: 'receiverId',
                  value: userId,
                },
              },
            ]"
            authority="Users_Partner_Connect"
            hide-edit
            hide-delete
            inline-actions
          />
        </b-tab>

        <b-tab :title="$t('Requests_Deleted')" lazy>
          <EntityList
            :config="config"
            :columns="columnsDeleted"
            :formatted-columns="formattedColumns"
            :form-fields="formFields"
            :custom-query="{
              personal: true,
              status: 'DELETED',
            }"
            :has-insert-btn="false"
            authority="Users_Partner_Connect"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BRow,
  BCol,
  BImg,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import { $themeConfig } from '@themeConfig'

import { getUserId, isSupplier } from '@/auth/utils'
import EntityList from '@/layouts/entity/EntityList.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import axios from '@/libs/axios'

import storeModule from '../partnerRequestStoreModule'

import requestStatusOptions from '../requestStatusOptions'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BRow,
    BCol,
    BImg,
    BTabs,
    BTab,
    BCard,
    EntityList,
  },
  data() {
    return {
      partnerEmail: '',
      email,
      required,
      loaded: false,
      baseConfig: {
        module: 'partner-requests',
        endpoint: 'partner-requests',
        actionsEndpoint: 'partner-requests/update-status',
        route: 'my-partner-requests',
        title: {
          single: this.$i18n.t('Partner Request'),
          plural: this.$i18n.t('Partner Requests'),
        },
      },
      showValidation: true,

    }
  },
  computed: {
    eshopUrl() {
      return process.env.VUE_APP_SHOP_URL
    },
    showRequests() {
      return (
        this.$refs.activeList &&
        (this.$refs.activeList.remainingEntries === null ||
          this.$refs.activeList.remainingEntries > 0)
      )
    },
    config() {
      return this.baseConfig
    },
    activeConfig() {
      const activeConfig = JSON.parse(JSON.stringify(this.baseConfig))
      activeConfig.limited = this.$store.getters['app-common/maxPartners']
      return activeConfig
    },
    userId() {
      return getUserId()
    },
    isSupplier() {
      return isSupplier()
    },
    appLogoImage() {
      const { appLogoImage } = $themeConfig.app
      return appLogoImage
    },
    columns() {
      return [
        { key: 'status', label: '', sortable: false },
        { key: 'sender', label: this.$t('Sender'), sortable: false },
        { key: 'receiver', label: this.$t('Receiver'), sortable: false },
        {
          key: 'creationDate',
          label: this.$t('Creation Date'),
          sortable: true,
        },
        { key: 'actions', label: this.$t('actions') },
      ]
    },

    columnsDeclined() {
      return [
        { key: 'status', label: '', sortable: false },
        { key: 'sender', label: this.$t('Sender'), sortable: false },
        { key: 'receiver', label: this.$t('Receiver'), sortable: false },
        {
          key: 'declinedDate',
          label: this.$t('Declined Date'),
          sortable: true,
        },
        { key: 'actions', label: this.$t('actions') },
      ]
    },

    columnsDeleted() {
      return [
        { key: 'status', label: '', sortable: false },
        { key: 'sender', label: this.$t('Sender'), sortable: false },
        { key: 'receiver', label: this.$t('Receiver'), sortable: false },
        { key: 'deletedDate', label: this.$t('Deleted Date'), sortable: true },
      ]
    },

    formattedColumns() {
      return [
        { key: 'status', type: 'status' },
        { key: 'creationDate', type: 'datetime' },
        { key: 'sender', type: 'partner-sender' },
        { key: 'receiver', type: 'partner-receiver' },
      ]
    },

    formFields() {
      return [
        {
          id: 'title',
          parent: 'sender',
          type: 'text',
          label: this.$t('Sender'),
          required: true,
          readonly: true,
        },
        {
          id: 'title',
          parent: 'receiver',
          type: 'text',
          label: this.$t('Receiver'),
          required: true,
          readonly: true,
        },
        {
          id: 'status',
          type: 'select',
          label: this.$t('Partnership Status'),
          options: requestStatusOptions,
          required: true,
        },
      ]
    },
  },
  created() {
    this.baseConfig.store = storeModule
    this.activeConfig.store = storeModule
  },
  mounted() {
    this.loaded = true
  },
  methods: {
    pendingExtraActions() {
      getUserId()
    },
    validationForm() {
  
      this.$refs.emailValidation.validate().then((success) => {
        if (success) {
          axios
            .post('partner-requests/invite', { email: this.partnerEmail })
            .then(() => {
              this.partnerEmail = ''
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('Request Sent successfully!'),
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('Something went wrong!'),
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
  },
}
</script>
